import React, { useEffect, useState } from "react";
import axios from "axios";

function CvList() {
  const [cvs, setCvs] = useState([]);

  useEffect(() => {
    // Fetch all CVs from the backend 
    axios
      .get("http://localhost:5000/cvs")
      .then((response) => {
        setCvs(response.data); // Set the fetched CVs to state
      }) 
      .catch((error) => {
        console.error("Error fetching CVs:", error); 
      });
  }, []);
  
    const handleDownload = (filePath) => {
        // Trigger file download by opening the file URL in a new tab
        //  const downloadLink = document.createElement("a");
        //  downloadLink.href = `http://localhost:5000/${filePath}`;
        //  downloadLink.download = true;
         window.open(`http://localhost:5000/${filePath}`, "_blank");
        //  downloadLink.click();
    };
   
  return ( 

    <div style={{ overflowX: "auto" }}>
    <h1 style={{ textAlign: "center" }}>APPLICANT CV</h1>
    <table
      style={{
        width: "100%",
        borderCollapse: "collapse",
        margin: "20px 0",
      }}
    >
      <thead>
        <tr
          style={{
            backgroundColor: "#f2f2f2",
            textAlign: "left",
          }}
        >
          <th style={tableHeaderStyle}>Sl</th>
          <th style={tableHeaderStyle}>Name</th>
          <th style={tableHeaderStyle}>Email</th>
          <th style={tableHeaderStyle}>Phone</th>
          <th style={tableHeaderStyle}>Remarks</th>
          <th style={tableHeaderStyle}>Position</th>
          <th style={tableHeaderStyle}>Shortlist</th>
          <th style={tableHeaderStyle}>Action</th>
        </tr>
      </thead>
      <tbody>
        {cvs.map((cv, index) => (
          <tr key={cv._id} style={index % 2 === 0 ? rowStyle1 : rowStyle2}> 
            <td style={cellStyle}>{index + 1}</td> 
            <td style={cellStyle}>{cv.name}</td> 
            <td style={cellStyle}>{cv.email}</td> 
            <td style={cellStyle}>{"0"+cv.phone}</td>
            <td style={cellStyle}>{cv.remarks}</td>
            <td style={cellStyle}>
              {/* Replace this with dynamic data for position if available */}
              {cv.position}
            </td>
            <td style={cellStyle}>
              {/* Replace with a checkbox or actual shortlist logic */}
              <input type="checkbox" />
            </td>
            <td style={cellStyle}>
              <button
                onClick={() => handleDownload(cv.cv)}
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#1976d2",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Download CV
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  );
}

const tableHeaderStyle = {
    padding: "10px",
    borderBottom: "2px solid #ddd",
    fontWeight: "bold",
    textAlign: "center",
  };
  
  const cellStyle = {
    padding: "10px",
    borderBottom: "1px solid #ddd",
    textAlign: "center",
  };
  
  const rowStyle1 = {
    backgroundColor: "#f9f9f9",
  };
  
  const rowStyle2 = {
    backgroundColor: "#ffffff",
  };

export default CvList;
