import React from 'react'
import { Col } from 'react-bootstrap'

const DocumentAdd = () => {
  return (
    <>
    
    <Col>
    <div className='documentAdd'>
     
        <p>Document</p> 
        <input type="file" name="File Upload" /> 
     
    </div>
    </Col>

    
    
    </>
  )
}

export default DocumentAdd