import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import { Col,Container } from 'react-bootstrap'
import Swal from 'sweetalert2' 
import { Modal, Box, Button, TextField, Alert, Checkbox } from "@mui/material";
import { useParams } from "react-router-dom"; 
      
const CareerDescription = () => { 
   
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    remarks: "",
    file: null,
  });

  const handleClose = () => {
    setOpen(false);
    setFormData({
      name: "",
      email: "",
      phone: "",
      remarks: "",
      file: null,
      agreedToTerms: false,
    });
  };

  const [errors, setErrors] = useState({});

  const validate = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = "Name is required.";
    if (!formData.email) tempErrors.email = "Email is required.";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      tempErrors.email = "Email is invalid.";
    if (!formData.phone) tempErrors.phone = "Phone number is required.";
    else if (!/^\d+$/.test(formData.phone))
      tempErrors.phone = "Phone number must be numeric.";
    // if (!formData.remarks)
    //   tempErrors.remarks = "Cover letter is required.";
    if (!formData.file) tempErrors.file = "Please upload your CV.";
    if (!formData.agreedToTerms)
      tempErrors.agreedToTerms = "You must agree to the terms and conditions.";
      
    setErrors(tempErrors); 
    return Object.keys(tempErrors).length === 0; 
  }

  // Handle text field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle checkbox changes
  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, agreedToTerms: e.target.checked });
  };

  // Handle file input changes
  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  // Form submission
  const handleForm = async (e) => {
    e.preventDefault(); 

    if (!validate()) {
      return; 
    }

    const data = new FormData();
    data.append("name", formData.name);
    data.append("email", formData.email);
    data.append("phone", formData.phone);
    data.append("remarks", formData.remarks);
    data.append("file", formData.file);
  
    try {
      const response = await fetch("http://localhost:5000/upload", {
        method: "POST",
        body: data,
      });
  
      const result = await response.json();
  
      if (response.ok) {
        alert("CV uploaded successfully!");
        handleClose();
      } else {
        alert(`Error: ${result.error}`);
      }
    } catch (error) {
      console.error("Error uploading CV:", error);
      alert("Failed to upload CV");
    }
  };

  const { id } = useParams();
  const job = jobs.find((job) => job.id === parseInt(id));

  const responsibilities = job.responsibilities.split('/').map(item => item.trim()).filter(item => item);
            
  return (
    <>     
      <Grid  container>
        <Grid style={{ padding: "3rem 3rem 0 3rem" }} item lg={9}>
          <div className="jobLeft">

            <p className="jobLeftHeader"> {job.post} </p>
            {/* <NavLink className="" to="/career/jobs/apply">  */}
              <Button onClick={handleOpen} className='job_button' variant="danger">Apply Now</Button> 
            {/* </NavLink>  */}                
          </div> 
          <div>
            <div className='jobDetailsPara'
              style={{
                background: "#fff",
                borderRadius: "5px",
                padding: "2rem",
              }}
            >
              <p className="roleHeader">Vacancy: {job.vacancy}</p>
              <p className="roleHeader">Job Responsibilities</p> 

              <p style={{ margin: "2rem 0", textAlign: "justify" }}>
                {/* {job.responsibilities} */}
                <ul style={{ lineHeight: '1.8' }}>
                  {responsibilities.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </p>
   
              <p className="roleHeader">General Information</p>
              <p style={{ margin: "2rem 1rem", fontSize: "16px" }}>
                <p>
                  {" "}
                  <span style={{ fontWeight: "600" }}>Location</span> :{" "}
                  <span>Dhaka</span>
                </p>
                <p>
                  {" "}
                  <span style={{ fontWeight: "600" }}>Deadline</span> :{" "}
                  <span>2025-01-31</span>
                </p>
                <p>
                  {" "}
                  <span style={{ fontWeight: "600" }}>Position</span> :{" "}
                  <span>Mid</span>
                </p>
                <p>
                  {" "}
                  <span style={{ fontWeight: "600" }}>Salary </span> :{" "}
                  <span>Negotiable</span>
                </p>
              </p> 
   
              <p className="roleHeader">Responsibilities</p>
              <p style={{ margin: "2rem 1rem", textAlign: "justify" }}>
                <p>
                  {" "}
                  &#x2022; Must have practical knowledge to install, configure,
                  update, maintain and troubleshoot networking equipment like
                  routers (MikroTik, CISCO), switches (MikroTik, CISCO) &
                  wireless devices.{" "}
                </p>
      
                <p>
                  &#x2022; Design, implement, and maintain the company's network
                  infrastructure, including local area networks (LANs), wide
                  area networks (WANs), and wireless networks.
                </p>

                <p>
                  &#x2022; Monitor network performance and troubleshoot network
                  issues whenever it is needed on an urgent basis. Proactively
                  analyze network, application and system log events and
                  identify abnormal behavior.{" "}
                </p>

                <p>
                  &#x2022; Collaborate with the ISP to resolve network-related
                  incidents and support requests.{" "}
                </p>
              </p>

              <p className="roleHeader">Requirements</p>

              <p style={{ margin: "2rem 1rem", textAlign: "justify" }}>
                <p>
                  {" "}
                  &#x2022; 2+ years of experience in network engineering and
                  administration.{" "}
                </p>

                <p>
                  &#x2022; Must have good knowledge of computer hardware and
                  software.
                </p>

                <p>
                  &#x2022; Monitor network performance and troubleshoot network
                  issues whenever it is needed on an urgent basis. Proactively
                  analyze network, application and system log events and
                  identify abnormal behavior.{" "}
                </p>

                <p>
                  &#x2022; Collaborate with the ISP to resolve network-related
                  incidents and support requests.{" "}
                </p>
              </p>
   
              <p className="roleHeader"> Compensation & Benefits </p>
              <p style={{ margin: "2rem 1rem", textAlign: "justify" }}>
                <p>
                  {" "}
                  &#x2022; Awesome culture - passionate, driven and fun team.{" "}
                </p>
                <p>&#x2022; Excellent learning environment.</p>
                <p>&#x2022; Daylight shifts. </p>
                <p>
                  &#x2022; Fully subsidized lunch, snacks, and unlimited
                  tea/coffee.{" "}
                </p>
                <p>&#x2022; Ability to grow and build a career. </p>
              </p>
            </div>
          </div>
        </Grid>
   
        <Grid className='gridLeft' item lg={3}>
          <div className="recentJobs"> 

            <h2>Recent Jobs</h2>

            <Col sm={12} md={6} lg={4}>
              <div className="jobBox2">
                <p className="jobHeader2">Tender Sales Manager</p>
              </div>
            </Col>

            <Col sm={12} md={6} lg={4}>
              <div className="jobBox2">
                <p className="jobHeader2">IT & Network Engineer</p>
              </div>
            </Col>
    
            <Col sm={12} md={6} lg={4}>
              <div className="jobBox2">
                <p className="jobHeader2">Senior Software Engineer</p>
              </div>
            </Col>
          </div>
        </Grid>
      </Grid>

      <Container
        style={{ marginTop: "2rem", background: "#fff" }}
        fluid="true"
        className=" copyrightSection text-center"
      >
        <a
          style={{ color: "#000", fontWeight: "500" }}
          className="copyrightText"
          href="#"
        >
          &copy; Copyright{" "}
          <strong style={{ color: "#1dc6ad" }}> Talukder </strong> Group Of
          Industries {new Date().getFullYear()}
        </a>
      </Container>



      {/* <Modal   
        size="xl"
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      > 
        <Box sx={{ ...style }}>
          <h2 id="child-modal-title">Upload File</h2> 
          <p id="child-modal-description">
            Select a file to upload using the input below.
          </p>
          
          <input
            type="file"
            onChange={handleFileChange}
            style={{ display: "block", marginBottom: "10px" }}
          /> 
     
          {errorMsg && 
          <Alert severity="warning"> {errorMsg}</Alert> 
          } 

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="success"
              color="primary"
              onClick={handleFileUpload}
            > 
              Upload 
            </Button>
            <Button variant="danger" color="secondary" onClick={handleClose}>
              Close
            </Button> 
          </div>
        </Box>
      </Modal> */}   


  <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="upload-modal-title"
      aria-describedby="upload-modal-description"
    >
      <form onSubmit={handleForm} encType="multipart/form-data">
        <Box
          sx={{
            width: { xs: "90%", sm: "70%", md: "50%", lg: "30%" },
            margin: "50px auto",
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: 24,
            overflow: "auto",
            maxHeight: "90vh",
          }}
        >
          <h2 id="upload-modal-title" style={{ textAlign: "center" }}>
            Upload Your CV
          </h2>
          <p id="upload-modal-description" style={{ textAlign: "center" }}>
            Fill in the form and upload your CV below.
          </p>

          {/* Name Field */}
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />

          {/* Email Field */}
          <TextField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />



          {/* Phone Field */}
          <TextField
            label="Phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />

{/* {errors.email && <Alert severity="warning">{errors.email}</Alert>} */}

          {/* Remarks Field */}
          <TextField
            label="Remarks"
            name="remarks"
            value={formData.remarks}
            onChange={handleChange}
            fullWidth
            multiline
            rows={2}
            margin="normal"
          />

          {/* Terms and Conditions Checkbox */}
          <label style={{ display: "block", margin: "10px 0" }}>
            <input
              type="checkbox"
              checked={formData.agreedToTerms}
              onChange={handleCheckboxChange}
              style={{ marginRight: "8px" }}
            />
            I agree to the terms and conditions
          </label>
          {errors.agreedToTerms && (
            <Alert severity="warning">{errors.agreedToTerms}</Alert>
          )}

          {/* File Upload */}
          <Button component="label" style={{ margin: "10px 0", display: "block" }}>
            <input type="file" name="file" onChange={handleFileChange} />
          </Button>
          {formData.file && <p>Selected File: {formData.file.name}</p>}
          {errors.file && <Alert severity="warning">{errors.file}</Alert>}

          {/* Submit Button */}
          <Button variant="contained" color="primary" type="submit" fullWidth>
            Submit
          </Button>
          <br />
          {/* Close Button */}
          <Button variant="outlined" color="secondary" onClick={handleClose} fullWidth>
            Close
          </Button>
        </Box>
      </form>
    </Modal>


    </>
  );
}

export default CareerDescription

const jobs = [
  {
    id: 1,
    post: "Software Engineer",
    dept: "Information Technology",
    vacancy: "5",
    responsibilities: "3 years of experiences in related field/Excellent Email & Verbal Communication/Proficiency in MS Word, Excel, Power Point, Drafting Critical issue solving/Knowledge in Real Estate sector, land Possession Takeover, Construction, Design & DAP/Experience in Customer Service, Negotiation, Critical customer dealing, Troubleshooting/3 years of experiences in related field/Incumbent should have excellent contacts and relation maintaining capability/A sophisticated personality and a proven track record of success in logistic related assignment is necessary for this position/Experienced in logistic management ",
    status: "Full-time",
    workplace: "Work at office",
    education: "Graduation from reputed university",
    experience: "3 years of experiences in related field/Excellent Email & Verbal Communication/Proficiency in MS Word, Excel, Power Point, Drafting Critical issue solving/Knowledge in Real Estate sector, land Possession Takeover, Construction, Design & DAP/Experience in Customer Service, Negotiation, Critical customer dealing, Troubleshooting/3 years of experiences in related field/Incumbent should have excellent contacts and relation maintaining capability/A sophisticated personality and a proven track record of success in logistic related assignment is necessary for this position/Experienced in logistic management ",
    salary: "35000",
    benefits : "",
  },

  {
    id: 2,
    post: "Manager",
    dept: "Human Resource",
    vacancy: "3",
    responsibilities: "3 years of experiences in related field/Excellent Email & Verbal Communication/Proficiency in MS Word, Excel, Power Point, Drafting Critical issue solving/Knowledge in Real Estate sector, land Possession Takeover, Construction, Design & DAP/Experience in Customer Service, Negotiation, Critical customer dealing, Troubleshooting/3 years of experiences in related field/Incumbent should have excellent contacts and relation maintaining capability/A sophisticated personality and a proven track record of success in logistic related assignment is necessary for this position/Experienced in logistic management ",
    status: "Full-time",
    workplace: "Work at office",
    education: "Graduation from reputed university",
    experience: "3 years of experiences in related field/Excellent Email & Verbal Communication/Proficiency in MS Word, Excel, Power Point, Drafting Critical issue solving/Knowledge in Real Estate sector, land Possession Takeover, Construction, Design & DAP/Experience in Customer Service, Negotiation, Critical customer dealing, Troubleshooting/3 years of experiences in related field/Incumbent should have excellent contacts and relation maintaining capability/A sophisticated personality and a proven track record of success in logistic related assignment is necessary for this position/Experienced in logistic management ",
    salary: "35000",
    benefits : "",
  },
  {
    id: 3,
    post: "Sales Admin ",
    dept: "UPVC",
    vacancy: "10",
    responsibilities: "3 years of experiences in related field/Excellent Email & Verbal Communication/Proficiency in MS Word, Excel, Power Point, Drafting Critical issue solving/Knowledge in Real Estate sector, land Possession Takeover, Construction, Design & DAP/Experience in Customer Service, Negotiation, Critical customer dealing, Troubleshooting/3 years of experiences in related field/Incumbent should have excellent contacts and relation maintaining capability/A sophisticated personality and a proven track record of success in logistic related assignment is necessary for this position/Experienced in logistic management ",
    status: "Full-time",
    workplace: "Work at office",
    education: "Graduation from reputed university",
    experience: "3 years of experiences in related field/Excellent Email & Verbal Communication/Proficiency in MS Word, Excel, Power Point, Drafting Critical issue solving/Knowledge in Real Estate sector, land Possession Takeover, Construction, Design & DAP/Experience in Customer Service, Negotiation, Critical customer dealing, Troubleshooting/3 years of experiences in related field/Incumbent should have excellent contacts and relation maintaining capability/A sophisticated personality and a proven track record of success in logistic related assignment is necessary for this position/Experienced in logistic management ",
    salary: "15000",
    benefits : "",
  },
];

