import React from 'react'
import CareerNavigation from '../components/Career/CareerNavigation'
import DocumentAdd from '../components/Career/DocumentAdd'

const JobApplyPage = () => {
  return (
    <>
    
    <div style={{background:"#ccc", height: "100vh"}}>
    <CareerNavigation />
    <DocumentAdd />
    </div>

    

    </>
  )
}

export default JobApplyPage