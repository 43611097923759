import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import talukderLogo from "../../asset/images/Logo/talukderName.png";
import { Link, NavLink } from "react-router-dom";

const CareerNavigation = () => {
  return (
    <>
      <Navbar className='carrerNav' data-bs-theme="light"> 
        <Container>
          <Navbar.Brand href="#home">
            <Link to="/">
              <Navbar.Brand href="#home">
                <img
                  alt="logo"
                  // data-aos="fade-down"
                  // data-aos-anchor="#example-anchor"
                  // data-aos-offset="100" 
                  // data-aos-duration="1000"
                  className="t_logo"
                  src={talukderLogo}
                  margin={{ padding: "10px 0" }}
                /> 
              </Navbar.Brand>
            </Link>
          </Navbar.Brand>
          <Nav className="ml-auto">
            <Nav.Link>
              <NavLink to="/" className="careerMenu">
                Home
              </NavLink>{" "}
            </Nav.Link>
            <Nav.Link>
              <NavLink to="/about" className="careerMenu">
                About Us
              </NavLink>
            </Nav.Link>
            {/* <Nav.Link href="#features">About Us</Nav.Link>             */}
          </Nav>
        </Container>
      </Navbar>

      {/* 
      <Nav.Link>
                <NavLink className={this.state.navbarItem} to="/career">
                  Career 
                </NavLink>    
              </Nav.Link>   */}
    </>
  );
}

export default CareerNavigation