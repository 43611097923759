import React from 'react'
import {useState, useEffect} from 'react'
import "./Career.css"
import { Container, Row, Col } from 'react-bootstrap'
// import Accordion from 'react-bootstrap/Accordion';
import AOS from "aos";
import Spinner from '../Spinner/Spinner';
import { FaArrowRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const Career = () => {

  useEffect(() => {
    AOS.init({
       offset: 120, 
       delay: 0, 
       easing: "ease",
       duration: 1500,
       disable: false,
       once: false,
       mirror: false,
       startEvent: 'DOMContentLoaded', 
       animatedClassName: 'aos-animate',
       initClassName: 'aos-init',
       useClassNames: false,
    });
  }, []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3200);
  }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Container style={{ marginTop: "5rem", marginBottom: "5rem" }}>
          <Row style={{ marginTop: "3rem" }}>
            <Col sm={12} md={6} lg={8}>
              <div className="opportunity">
                <p data-aos="fade-right" className="careerHeader">
                  Find Your <strong>Opportunity</strong>
                </p>
                <p className="careerText">
                  {" "}
                  At Talukder group of industries, we believe in providing our
                  employees with a fulfilling and rewarding career path. We
                  understand that each individual has unique career aspirations,
                  and we strive to provide opportunities that align with those
                  aspirations.{" "}
                </p>
              </div>
            </Col>
     
            <Col sm={12} md={6} lg={4}>
              <div style={{ marginTop: "2rem" }}>
                <p
                  data-aos="fade-down"
                  data-aos-anchor="#example-anchor"
                  data-aos-offset="500"
                  data-aos-duration="1500"
                  className="experience"
                >
                  Choose a job you love, and you will never have to work a day
                  in your life. <br /> —Confucius
                </p>
              </div>
            </Col>
          </Row>

          <hr />         
          <br />

            <Row>
              <p className="currentOpening">Current Openings</p>
              {jobs.map((job, index) => (
                <Col sm={12} md={6} lg={4} key={index}>
                  <div className="jobBox">
                    <p className="jobHeader">{job.post}</p>
                    <p className="jobApplyNow">
          
                      {/* <NavLink className="" to={`/jobs/${job.post}`} target="_blank"> */}

                      <NavLink
                        to={`/jobs/${job.id}`} // Pass only the job id in the URL
                        target="_blank"
                      >
  
                        <a
                          style={{
                            color: "red",
                            fontWeight: "400",
                          }}
                          className=" "
                        >
                          Apply Now <FaArrowRight />
                        </a>
                      </NavLink>
                    </p>
                  </div>
                </Col>
              ))}
            </Row>
      
        </Container>
      )}
    </>
  );
}

export default Career

const jobs = [
  {
    id: 1,
    post: "Software Engineer",
    dept: "Information Technology",
    vacancy: "5",
    responsibilities: "3 years of experiences in related field/Excellent Email & Verbal Communication/Proficiency in MS Word, Excel, Power Point, Drafting Critical issue solving/Knowledge in Real Estate sector, land Possession Takeover, Construction, Design & DAP/Experience in Customer Service, Negotiation, Critical customer dealing, Troubleshooting/3 years of experiences in related field/Incumbent should have excellent contacts and relation maintaining capability/A sophisticated personality and a proven track record of success in logistic related assignment is necessary for this position/Experienced in logistic management ",
    status: "Full-time",
    workplace: "Work at office",
    education: "Graduation from reputed university",
    experience: "3 years of experiences in related field/Excellent Email & Verbal Communication/Proficiency in MS Word, Excel, Power Point, Drafting Critical issue solving/Knowledge in Real Estate sector, land Possession Takeover, Construction, Design & DAP/Experience in Customer Service, Negotiation, Critical customer dealing, Troubleshooting/3 years of experiences in related field/Incumbent should have excellent contacts and relation maintaining capability/A sophisticated personality and a proven track record of success in logistic related assignment is necessary for this position/Experienced in logistic management ",
    salary: "35000",
    benefits : "",
  },

  {
    id: 2,
    post: "Manager",
    dept: "Human Resource",
    vacancy: "3",
    responsibilities: "3 years of experiences in related field/Excellent Email & Verbal Communication/Proficiency in MS Word, Excel, Power Point, Drafting Critical issue solving/Knowledge in Real Estate sector, land Possession Takeover, Construction, Design & DAP/Experience in Customer Service, Negotiation, Critical customer dealing, Troubleshooting/3 years of experiences in related field/Incumbent should have excellent contacts and relation maintaining capability/A sophisticated personality and a proven track record of success in logistic related assignment is necessary for this position/Experienced in logistic management ",
    status: "Full-time",
    workplace: "Work at office",
    education: "Graduation from reputed university",
    experience: "3 years of experiences in related field/Excellent Email & Verbal Communication/Proficiency in MS Word, Excel, Power Point, Drafting Critical issue solving/Knowledge in Real Estate sector, land Possession Takeover, Construction, Design & DAP/Experience in Customer Service, Negotiation, Critical customer dealing, Troubleshooting/3 years of experiences in related field/Incumbent should have excellent contacts and relation maintaining capability/A sophisticated personality and a proven track record of success in logistic related assignment is necessary for this position/Experienced in logistic management ",
    salary: "35000",
    benefits : "",
  },
  {
    id: 3,
    post: "Sales Admin ",
    dept: "UPVC",
    vacancy: "10",
    responsibilities: "3 years of experiences in related field/Excellent Email & Verbal Communication/Proficiency in MS Word, Excel, Power Point, Drafting Critical issue solving/Knowledge in Real Estate sector, land Possession Takeover, Construction, Design & DAP/Experience in Customer Service, Negotiation, Critical customer dealing, Troubleshooting/3 years of experiences in related field/Incumbent should have excellent contacts and relation maintaining capability/A sophisticated personality and a proven track record of success in logistic related assignment is necessary for this position/Experienced in logistic management ",
    status: "Full-time",
    workplace: "Work at office",
    education: "Graduation from reputed university",
    experience: "3 years of experiences in related field/Excellent Email & Verbal Communication/Proficiency in MS Word, Excel, Power Point, Drafting Critical issue solving/Knowledge in Real Estate sector, land Possession Takeover, Construction, Design & DAP/Experience in Customer Service, Negotiation, Critical customer dealing, Troubleshooting/3 years of experiences in related field/Incumbent should have excellent contacts and relation maintaining capability/A sophisticated personality and a proven track record of success in logistic related assignment is necessary for this position/Experienced in logistic management ",
    salary: "15000",
    benefits : "",
  },
];

