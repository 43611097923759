import React from 'react'
import PageTop from '../components/PageTop/PageTop'
import CareerNavigation from '../components/Career/CareerNavigation'
import TopNavigation from '../components/TopNavigation/TopNavigation'
import CareerDescription from '../components/Career/CareerDescription'

const JobDetailsPage = () => {
  return (
    <>
     <div style={{background:"#d9d9d9"}}>

    <CareerNavigation />
    <CareerDescription />
    
    </div>

    </>
  )
}

export default JobDetailsPage